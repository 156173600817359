import React, { useCallback, useEffect, useState } from "react";
import useAnalyticsEventTracker from "../../../../common/hooks/use-analytics-event-tracker";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { useKeycloak } from "@react-keycloak/web";

import {
  IconDownload,
  IconMacOS,
  IconWindowsOS,
} from "../../../../assets/icons/icon";

import "./download-studio.scss";
import { createRef } from "react";
import { useLocation } from "react-router-dom";

export function AuthenticatedLink({
  urlDownload,
  filename,
  children,
  setShowModal,
  skipLogin,
  gaEventTracker,
  trackingDownload,
  downloadOs,
}) {
  const { keycloak } = useKeycloak();
  const [selectOs, setSelectOs] = useState("");

  const link = createRef();
  const handelDownload = () => {
    if (selectOs !== downloadOs || link.current.href) {
      return;
    }

    const href = urlDownload[0]?.attributes.url;
    link.current.download = filename;
    link.current.href = href;

    gaEventTracker(downloadOs);
    trackingDownload();

    link.current.click();
  };

  useEffect(() => {
    if (skipLogin) {
      handelDownload();
    }
  }, [skipLogin, selectOs]);

  const handleAction = async () => {
    setSelectOs(downloadOs);
    if (!keycloak?.authenticated && !skipLogin) {
      return setShowModal(true);
    }
    if (keycloak?.authenticated) {
      handelDownload();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      role="button"
      ref={link}
      onClick={handleAction}
      // target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

const DownloadVersionWinOs = ({
  gaEventTracker,
  urlDownload,
  trackingDownload,
  setShowModal,
  currentTab,
  skipLogin,
}) => {
  return (
    <article
      className={`download-os ${
        currentTab === "current" ? "download-os-bg-current" : ""
      }`}
    >
      <IconWindowsOS />
      <p className="divider-20">For Windows OS</p>
      <AuthenticatedLink
        setShowModal={setShowModal}
        skipLogin={skipLogin}
        gaEventTracker={gaEventTracker}
        trackingDownload={trackingDownload}
        urlDownload={urlDownload}
        downloadOs="downloadStudioWin"
      >
        <button className={`download-btn`}>
          {urlDownload[0]?.attributes.version}
        </button>
      </AuthenticatedLink>
    </article>
  );
};

const DownloadVersionMacOs = ({
  gaEventTracker,
  urlDownloadIntel,
  urlDownloadApple,
  trackingDownload,
  setShowModal,
  currentTab,
  skipLogin,
}) => {
  return (
    <article
      className={`download-os download-os-mac ${
        currentTab === "current" ? "download-os-bg-current" : ""
      }`}
    >
      <IconMacOS />
      <p className="divider-20">For MacOS</p>
      <div className="download-wrapper-btn">
        <AuthenticatedLink
          setShowModal={setShowModal}
          skipLogin={skipLogin}
          gaEventTracker={gaEventTracker}
          trackingDownload={trackingDownload}
          urlDownload={urlDownloadIntel}
          downloadOs="downloadStudioMac"
        >
          <button className={`download-btn`} type="button">
            Intel processor: {urlDownloadIntel[0]?.attributes.version}
          </button>
        </AuthenticatedLink>

        <AuthenticatedLink
          setShowModal={setShowModal}
          skipLogin={skipLogin}
          gaEventTracker={gaEventTracker}
          trackingDownload={trackingDownload}
          urlDownload={urlDownloadApple}
          downloadOs="downloadStudioMac"
        >
          <button
            className={`download-btn ${
              currentTab === "lts" ? "download-btn-disabled" : ""
            }`}
            type="button"
            disabled={currentTab === "lts"}
          >
            {currentTab === "lts"
              ? " Apple silicon: Coming soon"
              : `Apple silicon: ${urlDownloadApple[0]?.attributes.version}`}
          </button>
        </AuthenticatedLink>
      </div>

      {/* <div className="download-choose-version">
        <div className="download-block-content">
          <p>Intel processor: </p>
          <AuthenticatedLink
            setShowModal={setShowModal}
            skipLogin={skipLogin}
            gaEventTracker={gaEventTracker}
            trackingDownload={trackingDownload}
            urlDownload={urlDownloadIntel}
            downloadOs="downloadStudioMac"
          >
            {urlDownloadIntel[0]?.attributes.version}
          </AuthenticatedLink>
        </div>
        <div className="download-block-content">
          <p>
            Apple silicon: <span>Comming soon</span>
          </p>
        </div>
      </div> */}
    </article>
  );
};

const DownloadStudio = ({ dataStudio, showModal, setShowModal, skipLogin }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentTab, setCurrentTab] = useState("current");
  const gaEventTracker = useAnalyticsEventTracker("Download");
  const appInsights = useAppInsightsContext();

  const paramsObj = Array.from(queryParams.entries()).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );

  const trackingDownloadStudioWin = useTrackEvent(
    appInsights,
    paramsObj?.source
      ? `trackingDownloadStudioWinFrom${paramsObj?.source}`
      : "trackingDownloadStudioWin",
    paramsObj?.source
      ? `trackingDownloadStudioWinFrom${paramsObj?.source}`
      : "trackingDownloadStudioWin"
  );
  const trackingDownloadStudioMac = useTrackEvent(
    appInsights,
    paramsObj?.source
      ? `trackingDownloadStudioWinFrom${paramsObj?.source}`
      : "trackingDownloadStudioMac",
    paramsObj?.source
      ? `trackingDownloadStudioWinFrom${paramsObj?.source}`
      : "trackingDownloadStudioMac"
  );

  function filterData(dataOrigin = [], platform, versionType, os, version) {
    return dataOrigin.filter(
      (item) =>
        item.attributes.platform === platform &&
        item.attributes.versionType === versionType &&
        (os ? item.attributes.os === os : true) &&
        (version ? item.attributes.version === version : true)
    );
  }

  const winOSLastVersion = filterData(dataStudio["lts"], "Windows", "LTS");
  const winOSCurrentVersion = filterData(
    dataStudio["current"],
    "Windows",
    "CURRENT"
  );
  const macOSIntelLastVersion = filterData(dataStudio["lts"], "MacOS", "LTS");
  const macOSIntelCurrentVersion = filterData(
    dataStudio["current"],
    "MacOS",
    "CURRENT"
  );
  const macOSAppleLastVersion = filterData(
    dataStudio["lts"],
    "MacOS_ARM",
    "LTS"
  );
  const macOSAppleCurrentVersion = filterData(
    dataStudio["current"],
    "MacOS_ARM",
    "CURRENT"
  );

  return (
    <section className="download-studio">
      <h1 className="divider-40">Download akaAT Studio here! </h1>
      <div className="download-os-head">
        <div
          onClick={() => setCurrentTab("lts")}
          className={`os-head-left ${
            currentTab === "lts" ? "os-head-active" : ""
          }`}
        >
          <p>LTS</p>
          <span>Recommended For Most User</span>
        </div>
        <div
          onClick={() => setCurrentTab("current")}
          className={`os-head-right ${
            currentTab === "current" ? "os-head-active" : ""
          }`}
        >
          <p>CURRENT</p>
          <span>Lastest Feature</span>
        </div>
      </div>
      <div className="download-os-container divider-35">
        <DownloadVersionWinOs
          currentTab={currentTab}
          showModal={showModal}
          skipLogin={skipLogin}
          setShowModal={setShowModal}
          gaEventTracker={gaEventTracker}
          urlDownload={
            currentTab === "lts" ? winOSLastVersion : winOSCurrentVersion
          }
          trackingDownload={trackingDownloadStudioWin}
        />
        <DownloadVersionMacOs
          currentTab={currentTab}
          showModal={showModal}
          skipLogin={skipLogin}
          setShowModal={setShowModal}
          gaEventTracker={gaEventTracker}
          urlDownloadIntel={
            currentTab === "lts"
              ? macOSIntelLastVersion
              : macOSIntelCurrentVersion
          }
          urlDownloadApple={
            currentTab === "lts"
              ? // ? macOSAppleLastVersion
                []
              : macOSAppleCurrentVersion
          }
          trackingDownload={trackingDownloadStudioMac}
        />
      </div>
      <div className="download-os-requirement">
        <p className="divider-35">
          CMD:{" "}
          <a
            href="https://docs.akaat.com/Version_3/Agent/1.2%20Start%20akaAT%20Agent/"
            target="_blank"
            rel="noreferrer"
          >
            How to run akaAT in non-GUI mode{" "}
          </a>
        </p>

        <div className="requirement-item divider-6">
          <div className="requirement-item-left">&nbsp;</div>
          <div className="requirement-item-right bold-text font-archia">
            Requirement
          </div>
        </div>
        <div className="requirement-item divider-6">
          <div className="requirement-item-left font-archia">
            Operating System{" "}
          </div>
          <div className="requirement-item-right font-archia">
            Windows 10, Windows 11, macOS 10.14+, Linux 20.04 (Ubuntu based)
          </div>
        </div>
        <div className="requirement-item divider-6">
          {" "}
          <div className="requirement-item-left font-archia">CPU </div>
          <div className="requirement-item-right font-archia">
            Minimum: {">"} 4 cores [x86], Core speed: 3GHz{" "}
            <span className="font-archia bold-text ">Mac: Intel version</span>
          </div>
        </div>
        <div className="requirement-item divider-6">
          {" "}
          <div className="requirement-item-left font-archia">Memory</div>
          <div className="requirement-item-right font-archia">
            akaAT Studio Minimum: 4 GB RAM free (64-bit) Recommended: 8 GB RAM
            (64-bit).
          </div>
        </div>
        <div className="requirement-item divider-6">
          {" "}
          <div className="requirement-item-left font-archia">Hard Drive</div>
          <div className="requirement-item-right font-archia">
            At least 2 GB available hard disk space. Extra disk space is
            required depending on project source codes and generated execution
            reports.
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadStudio;
