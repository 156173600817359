export const ENDPOINTS = {
  LOGIN: "/authen/login",
  HOME: "",
};

export const BASE_URL_FORUM = process.env.REACT_APP_BASE_URL_FORUM;
export const BASE_URL_SUPPORT = process.env.REACT_APP_BASE_URL_SUPPORT;
export const BASE_URL_EVENT = process.env.REACT_APP_BASE_URL_EVENT;
export const BASE_URL_AUTH = process.env.REACT_APP_BASE_URL_AUTH;

export const ENDPOINTS_AUTHENTICATION = {
  UPLOAD_IMAGE: "https://manager.akaat.com/api/v2/automation/upload",
  UPDATE_USER: `${BASE_URL_AUTH}/manager/user`,
  UPLOAD_PASSWORD: `${BASE_URL_AUTH}/manager/user`,
};

export const ENDPOINTS_FORUM = {
  HOT_TOPIC: `${BASE_URL_FORUM}/top.json?ascending=false`,
  LATEST_TOPIC: `${BASE_URL_FORUM}/latest.json?ascending=false`,
  ANNOUNCEMENT_TOPIC: `${BASE_URL_FORUM}/c/announcement/10/l/latest.json?ascending=false`,
};

export const ENDPOINTS_SUPPORT = {
  ANNOUNCEMENT: `${BASE_URL_SUPPORT}/api/announcements`,
  SUCCESS_STORIES: `${BASE_URL_SUPPORT}/api/success-stories/?sort=id:DESC&locale=en&populate=*`,
  WEBINARS: `${BASE_URL_SUPPORT}/api/webinars/?sort=id:DESC&locale=en&populate=*`,
  EVENTS: `${BASE_URL_SUPPORT}/api/events/?sort=id:DESC&locale=en&populate[0]=image&populate[1]=seo.metaImage&populate[2]=seo.metaSocial.image`,
  BLOGS: `${BASE_URL_SUPPORT}/api/blogs/?sort=id:DESC&locale=en&populate[0]=image&populate[1]=seo.metaImage&populate[2]=seo.metaSocial.image`,
  NEWS: `${BASE_URL_SUPPORT}/api/news/?sort=id:DESC&locale=en&populate[0]=image&populate[1]=seo.metaImage&populate[2]=seo.metaSocial.image`,
  DOWNLOAD: `${BASE_URL_SUPPORT}/api/downloads/?sort=id:DESC&locale=en&populate=*`,
  DOWNLOAD_AKA_TOOL_VERSIONS_LTS: `${BASE_URL_SUPPORT}/api/versions/?sort=id:DESC&filters[type][$eq]=Studio&filters[versionType][$eq]=LTS&pagination[page]=1&pagination[pageSize]=10&locale=en&populate=*`,
  DOWNLOAD_AKA_TOOL_VERSIONS_CURRENT: `${BASE_URL_SUPPORT}/api/versions/?sort=id:DESC&filters[type][$eq]=Studio&filters[versionType][$eq]=CURRENT&pagination[page]=1&pagination[pageSize]=10&locale=en&populate=*`,

  RELEASE_NOTES: `${BASE_URL_SUPPORT}/api/release-notes/?sort=id:DESC&locale=en&populate=*`,
};


export const ENDPOINTS_EVENT = {
  CONTACT: `${BASE_URL_EVENT}/email/send-contact`,
  RECEIVE_EVENTS: `${BASE_URL_EVENT}/email/receive-event`,
  ATTEND_EVENTS: `${BASE_URL_EVENT}/email/attend-event`,
};
